import Vue from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag';
import '@/assets/styles/main.scss';

Vue.config.productionTip = true;
Vue.use(VueGtag, {
  config: { id: 'G-E7VMSHQNR7' }
});

new Vue({
  render: h => h(App)
}).$mount('#app');
